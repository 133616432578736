@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

















































































































































































.registration-details__container {
  min-width: size(360);
  z-index: 1;
}

.registration-details__fields {
  &::after {
    content: '';
    display: block;
    margin: -0.9rem auto 2rem auto;
    width: 80%;
    border-bottom: size(3) solid $primary;
  }
}

.registration-details__error {
  // max-width: size(800);
}

.login-instead-button {
  left: 105%;
  white-space: nowrap;
}

.registration-details__svg {
  &--lady {
    width: size(179);
    height: size(262);
  }

  &--dude {
    width: size(126);
    // height: size(310);
  }

  &--plant {
    width: size(179);
    // height: size(137);
  }
}
