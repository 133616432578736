@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';








































































































































































































































.stepper__width {
  height: 100%;
  width: 68vw;
}
